<template>
  <div class="search-results flex-1">
    <section class="banner search-results__banner">
      <div class="container">
        <h2 v-text="$t('Search results')"></h2>
      </div>
    </section>
    <section class="search-results__list">
      <div class="container">
        <div v-for="(group, index) in groupedBooks" :key="index" class="row">
          <div
            v-for="(book, i) in group"
            :key="book.id"
            class="col-12 col-lg-6"
          >
            <book-card :key="book.id" :book="book" :index="i" />
          </div>
        </div>

        <div v-if="isLoading" class="m-auto t-center pt-4">
          <span class="spinner mx-auto mb-2"></span>
          <h2>{{ $t('Loading') }}...</h2>
        </div>

        <div
          v-if="pagination && pagination.hasNextPage() && !isLoading"
          class="t-center"
        >
          <button
            class="btn btn--secondary btn--view-all"
            @click="
              fetchBooks(
                pagination.getNextPage(),
                pagination.nextGoogleStartBookIndex
              )
            "
            v-text="$t('View All')"
          ></button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import books from '@/api/books';
import _chunk from 'lodash.chunk';
import _uniqBy from 'lodash.uniqby';
import BookCard from '@/components/bookCard/BookCard';

const QUERY_PARAMS = {
  BOOLEANS: [
    'isCharacterNames',
    'isExactYear',
    'isExactYearBc',
    'isFromBc',
    'isPerfectMatch',
    'isRealCharacters',
    'isScreenAdaptations',
    'isToBc',
    'isTrueEvents',
  ],
  ARRAYABLE: [
    'places',
    'googlePlaceIds',
    'tags',
    'decades',
    'centuries',
    'genre',
    'realCharacters',
    'trueEvents',
    'characterNames',
    'screenAdaptations',
  ],
};
export default {
  components: {
    BookCard,
  },

  data() {
    return {
      books: [],
      pagination: null,
      perPage: 10,
      isLoading: false,
    };
  },

  computed: {
    groupedBooks() {
      return _chunk(this.books, 2);
    },

    query() {
      const { query } = this.$route;
      const params = {};

      Object.keys(query).forEach((key) => {
        if (key === 'googlePlaceIds') {
          return;
        }

        if (QUERY_PARAMS.ARRAYABLE.includes(key)) {
          params[key] = query[key].split(/\s*,\s*/).filter(Boolean);
        } else if (QUERY_PARAMS.BOOLEANS.includes(key)) {
          params[key] = query[key].toString() === 'true';
        } else {
          params[key] = query[key];
        }
      });

      if (query.places) {
        const placeNames = query.places.split(/\s*,\s*/);
        const googlePlaceIds = query.googlePlaceIds.split(/\s*,\s*/);

        params.places = placeNames.map((name, index) => ({
          name: name.trim(),
          googlePlaceId: googlePlaceIds[index],
        }));
      }

      return params;
    },

    isSearchBasic() {
      return Object.keys(this.$route.query).includes('queryString');
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.books = [];
        this.fetchBooks();
      },
      deep: true,
    },
  },

  created() {
    this.fetchBooks();
  },

  methods: {
    search(page = 1, googleStartBookIndex = 0) {
      return this.isSearchBasic
        ? this.searchBasic(page, googleStartBookIndex)
        : this.searchAdvance(page);
    },

    searchAdvance(page = 1) {
      return books.searchAdvance({
        ...this.query,
        page,
        perPage: this.perPage,
        language: this.$i18n.locale,
      });
    },

    searchBasic(page = 1, googleStartBookIndex = 0) {
      return books.searchBasic({
        ...this.query,
        page,
        googleStartBookIndex,
        perPage: this.perPage,
        language: this.$i18n.locale,
      });
    },

    async fetchBooks(page = 1, googleStartBookIndex = 0) {
      this.isLoading = true;
      try {
        const { results, pagination } = await this.search(
          page,
          googleStartBookIndex
        );

        this.pagination = pagination;
        this.books = _uniqBy(this.books.concat(results), ({ id }) => id);

        if (this.books.length === 0) {
          this.$router.replace({
            name: 'books.no-result',
            params: this.$route.params,
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './books.scss';
</style>
